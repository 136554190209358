import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import MortgageCalculator from "../../components/MortgageCalculator";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const RechtePflichtenZukunftigeHauseigentumerin = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Deine Rechte und Pflichten als zukünftige Hauseigentümer*in" showCalc={false} />
            <Article>
                <h3>Deine Rechte und Pflichten als zukünftige Hauseigentümer*in</h3>
                <p>
                    Du hast dein Traumhaus gefunden, seine Finanzierung auf den Weg gebracht und auch schon den
                    Kaufvertrag unterschrieben. Voller Sehnsucht wartest du nun auf die Übergabe der Schlüssel. Um dir
                    die Wartezeit etwas zu verkürzen, klären wir dich im folgenden Beitrag darüber auf, welche Rechte
                    und Pflichten mit dem Kauf eines Hauses auf dich zukommen.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h3>Was heißt es überhaupt, Hauseigentümer*in zu sein?</h3>
                <p>
                    Hier gilt es zunächst eine wichtige Unterscheidung zu treffen: die zwischen Eigentümer*in und
                    Besitzer*in. Bist du Eigentümer*in einer Immobilie, so hast du – juristisch gesprochen – an ihr das
                    umfassende dingliche Recht. Das bedeutet, dass du über das betreffende Objekt nach deiner Willkür
                    verfügen und den Zugang anderer Personen zu diesem regulieren kannst. Eigentümer*in bist du in
                    Österreich ab der Eintragung in das Grundbuch.
                </p>
                <p>
                    Im Unterschied dazu hast du als Hausbesitzer*in zwar augenblicklich Gewalt über die Immobilie, bist
                    in deinem Handlungsspielraum jedoch eingeschränkt: Während dir das Recht zur Nutzung des Objektes
                    zukommt (so etwa im Falle eines Mietverhältnisses), legt der*die Eigentümer*in fest, was genau zu
                    tun und was zu unterlassen ist.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern!
                    </a>
                </p>
                <hr />

                <h3>Was sind meine Rechte als Hauseigentümer*in?</h3>
                <p>
                    In deiner neuen Rolle als Hauseigentümer*in kommt dir eine Vielzahl an Rechten zu. Dazu zählen die
                    Rechte auf:
                </p>
                <h4>Nutzung nach eigenem Belieben</h4>
                <p>
                    Als Eigentümer*in einer Immobilie kannst du diese nach deinen Wünschen und Vorstellungen umgestalten
                    und renovieren. Auch die Vermietung des Objekts steht dir selbstverständlich zu. Ferner kann dein
                    Haus bei der Aufnahme eines Kredits als Sicherheit dienen. Im Bedarfsfall kann es zudem jederzeit
                    verkauft werden.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <h4>Herausgabe</h4>
                <p>
                    Nehmen andere Personen deine Immobilie unrechtmäßig in Besitz, so hast du als Eigentümer*in darüber
                    hinaus das Recht, mit einer Eigentumsklage nach § 366 ABGB die Herausgabe zu fordern. Allerdings nur
                    dann, wenn es sich tatsächlich um unrechtmäßige Besitzer*innen und nicht um Personen handelt, mit
                    denen du etwa in einem Mietverhältnis stehst. Sollten Dritte die Immobilie unrechtmäßig nutzen,
                    bevor du in das Grundbuch eingetragen wurdest, so hast du die Möglichkeit, eine Klage aus rechtlich
                    vermutetem Eigentum nach § 372 ABGB einzureichen.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Jetzt Beratunsgtermin vereinbaren!
                    </a>
                </p>
                <h4>Schutz vor Immissionen der Nachbarn</h4>
                <p>
                    Gemäß § 364 Abs 2 ABGB steht es dir als Eigentümer*in einer Liegenschaft zu, deinen Nachbarn
                    Tätigkeiten, die Abwasser, Rauch, Gerüche, Geräusche usw. verursachen, die auf deinem Grundstück
                    wahrnehmbar sind bzw. auf dieses einwirken, dann zu untersagen, wenn sie über das ortsübliche Ausmaß
                    hinausgehen. Um dieses Recht einzufordern, hast du die Möglichkeit, auf Unterlassung zu klagen. In
                    einigen Fällen besteht allerdings Selbsthilfepflicht: Etwa wenn die Blätter, Äste oder Zweigen von
                    Pflanzen, die sich auf dem Nachbargrundstück befinden, auf deines überhängen. In diesem Fall darfst
                    du sie kürzen – das fremde Grundstück dabei jedoch nicht ohne Erlaubnis betreten.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Jetzt beraten lassen!
                    </a>
                </p>
                <h4>Unterlassung und Beseitigung von Störungen durch Dritte</h4>
                <p>
                    Blockiert beispielsweis das Auto eines*einer Fremden deine Einfahrt – wird also unrechtmäßig in
                    deinen Besitz eingegriffen –, so kannst du eine Besitzstörungsklage anstrengen bzw. diese androhen.
                    Keinesfalls solltest du im genannten Fall jedoch von Eigenjustiz gebrauch machen: Das eigenhändige
                    Abschleppen eines fremden Fahrzeugs ist in den meisten Fällen ebenfalls rechtswidrig.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Zum Expertengespräch
                    </a>
                </p>
                <h4>Ausgleich bei gewerblich sowie industriell verursachten Schäden</h4>
                <p>
                    Solltest du einen Schaden durch eine Anlage erleiden, die sich auf einem benachbarten Grundstück
                    befindet und behördlich genehmigt wurde, so hast du gemäß § 364a ABGB den Anspruch auf
                    Schadensersatz. Mit diesem wird nicht nur der entstandene Sachschaden, sondern auch die
                    wertminderung deiner Immobilie sowie etwaige Umsatzeinbußen abgedeckt. Es muss sich allerdings auf
                    einen materiellen Schaden handeln.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Sichere dir jetzt deine optimale Immobilienfinanzierung
                    </a>
                </p>
                <hr />

                <h3>Werden meine Rechte als Hauseigentümer*in in irgendeiner Weise eingeschränkt?</h3>
                <p>
                    Die Antwort lautet: leider ja. Wie wir gerade gesehen habe, hast du als Eigentümer*in zwar
                    zahlreiche Rechte; allerdings musst du auch einige VOrschriften beachten. Dazu zählen:
                </p>
                <h4>Bauvorschriften</h4>
                <p>
                    Bei der Renovierung und Umgestaltung deiner Immobilie, über die du grundsätzlich frei verfügen
                    kannst, musst du dich an das lokale Baurecht halten. Planst du dein Haus umzubauen oder zu
                    vergrößern, musst du dieses Vorhaben daher mit dem örtlichen Bauamt absprechen. Dieses lädt dann zu
                    einem Parteiengehör, im Zuge dessen die Beteiligten und Betroffenen Einsicht in die entsprechende
                    Akte sowie die Möglichkeit zur Äußerung von Einwänden erhalten.
                </p>
                <h4>Servituten</h4>
                <p>
                    Dieser Begriff bezieht sich auf teilweise Nutzungsrechte an Immobilien, die fremdes Eigentum sind.
                    Servituten (auch Grunddienstbarkeiten) können testamentarisch, durch Ersitzung, ein Teilungsurteil
                    oder schlicht gesetzlich festgelegt sein und haben zur Folge, dass der*die Eigentümer*in bestimmte
                    Handlungen zu dulden bzw. selbst zu unterlassen hat – im Falle einer geerbten Liegenschaft etwa die
                    Mitbenutzung des Gartens durch Verwandte, die testementarisch festgesetzt wurde. Da Servituten – wie
                    übrigens alle Nutzungsrechte – im Grundbuch vermerkt sind, solltest du dieses unbedingt noch vor der
                    Bezahlung deines neuen Hauses sichten.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h3>Welche Pflichten habe ich als Hauseigentümer*in</h3>
                <p>
                    Neben den gerade genannten Vorschriften, die deine Rechte als Eigentümer*in einschränken gibt es
                    auch dezidierte Pflichten, denen du in dieser Rolle nachkommen musst.
                </p>
                <h4>Abgrenzung zum Nachbargrundstück</h4>
                <p>
                    Nach §858 ABGB hast du als Hauseigentümer*in die Pflicht, auf der rechten Seiten des Haupteinganges
                    eine Abgrenzung zum Nachbargrundstück in Form eines Zauns, einer Mauer, Hecke o.Ä. anzubringen. Die
                    Kosten dafür musst du selbst übernehmen.
                </p>
                <h4>Verkehrssicherungspflicht</h4>
                <p>
                    Da dein Grundstück andere Personen nicht gefährden darf, bist du im Winter dazu verpflichtet,
                    sämtliche Gehwege von Schnee zu befreien, die von deiner Liegenschaft höchstens drei Meter weit
                    entfernt sind, bzw. sie bei Glatteis zu streuen. Zudem bist du in diesem Radius für die Beseitigung
                    von Schneehaufen verantwortlich – auch dann, wenn sie von behördlichen Räumungsfahrzeugen versucht
                    wurden. Lose oder morsche Äste und Zweige dürfen – besonders im Falle eines Sturms – ebenfalls keine
                    Gefahr für Passant*innen darstellen.
                </p>
                <h4>Nachbarschaftliche Rücksichtnahme</h4>
                <p>
                    Mit dem bereits genannten Recht auf Schutz vor nachbarschaftlichen Immissionen geht schließlich das
                    Rücksichtsnahmegebot einher: Es ist 2004 in Kraft getreten und verpflichtet dich, bei der Ausübung
                    deiner Rechte auf die Eigentümer*innen angrenzender Grundstücke Rücksicht zu nehmen.
                </p>
                <hr />

                <h3>Noch Fragen? miracl berät dich beim Hauskauf</h3>
                <p>
                    Solltest du zum Thema Hauskauf noch weitere Fragen haben, freuen wir uns, von dir zu hören! Unsere
                    unabhängigen Finanzexpert*innen stehen dir in einem persönlichen Online-Gespräch bei allen Facetten
                    der{" "}
                    <Link to="/artikel/immobilienfinanzierung/" target="_blank" rel="noreferrer noopener">
                        Immobilienfinanzierung
                    </Link>{" "}
                    mit Rat und Tat zur Seite. Vom unverbindlichen Erstgespräch über die Sondierung von Kreditangeboten
                    bis hin zur Unterzeichnung des Kaufvertrags: miracl beleitet dich auf deinem Weg zum Traumhaus!
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Jetzt kostenloses Beratungsgespräch vereinbaren!
                    </a>
                </p>
            </Article>
            <MortgageCalculator noTopMargin defaultTerm={35} defaultMortgage={50000} />

            <BreadcrumbList page={"rechtePflichtenAlsZukunftigeHauseigentumerin"}></BreadcrumbList>
            <ArticleStructuredData
                page={"rechtePflichtenAlsZukunftigeHauseigentumerin"}
                heading={"Deine Rechte und Pflichten als zukünftige Hauseigentümer*in"}
            />
        </Layout>
    );
};

export default RechtePflichtenZukunftigeHauseigentumerin;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: {
                    in: [
                        "header"
                        "footer"
                        "page.artikel.rechte-und-pflichten-als-zukunftige-hauseigentumerin"
                        "mortgageCalc"
                    ]
                }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
